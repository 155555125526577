:root {
  --text: #000000;
  --border: #d3d3d3;
  --hover: #808080;
  --overlay: rgba(211, 211, 211, 0.8);
}
.filter-button-group,
hr {
  margin: 1em;
}
.filterBtn {
  border-radius: 8px;
  margin: 0.4em 0.5em;
  cursor: pointer;
}
.filterBtn:hover {
  background-color: var(--border) !important;
}
.is-checked {
  background-color: var(--border) !important;
}
.gridIsotope {
  margin-top: 1% !important;
  margin-bottom: 5%;
  margin-right: unset;
  margin-left: unset !important;
  min-height: 50em;
}
.grid-item {
  display: flex;
  flex-direction: column !important;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 1em;
  margin-bottom: 5em;
}
.gridImg {
  border: 2px solid var(--border);
  border-bottom: 1px;
  width: 20em;
  height: 22em;
  -o-object-fit: cover;
     object-fit: cover;
}
.gridContent {
  width: 100%;
  min-height: 15em;
  width: 20em;
  border: 2px solid var(--border);
  padding: 1em 1em;
  display: flex;
  flex-direction: column;
  word-break: break-word;
}
.gridContent p,
.gridContent a {
  width: -moz-fit-content;
  width: fit-content;
  margin-right: 0;
}
.gridContentNameDiv {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}
.gridContentName {
  font-weight: bold;
  font-size: 1.2em;
}
.stackWrapper {
  display: initial;
  justify-content: space-between;
  align-items: center;
}
.stackWrapper div:nth-child(2) {
  align-items: center;
  margin: 2em 0 0 0;
}
.MuiStack-root {
  align-items: center;
}
.stackWrapper div p {
  word-break: normal;
  min-height: 20px;
}
.stackWrapper div:first-child p {
  padding-right: 0.3em;
}
.gridContentTagDiv {
  display: flex;
  justify-content: center;
  padding: 0.5em;
}
.noContent {
  opacity: 0;
}
.gridContentTag {
  border-radius: 50px;
}
.gridContentTag,
.filterBtn {
  background-color: transparent;
  color: var(--text);
  border: 2px solid var(--border);
  padding: 0.4em 1em;
}
.gridContent a i {
  font-size: 2em;
  color: var(--border);
}
.gridContent a i:hover {
  color: var(--hover);
}
.gridHover {
  display: none;
  background-color: var(--overlay);
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
}
.gridImg:hover ~ .gridHover,
.gridHover:hover {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gridHoverBtn {
  border: 2px solid var(--border);
  border-radius: 8px;
  padding: 0.3em 1em;
  background-color: var(--border);
  color: black;
  font-size: 14px;
  text-align: center;
}
.gridHoverBtn:hover {
  background-color: var(--hover);
  color: black;
}
.gridContentTagContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.mailplaceholder {
  height: 24px;
  margin-top: 16px;
}
.MuiGrid-root .ri-mail-line {
  color: #D1D1D1 !important;
  border-radius: 25px;
  padding: 3px;
}
.MuiGrid-root .ri-mail-line:hover {
  color: black !important;
}
@media only screen and (max-width: 600px) {
  .lightgalleryUl {
    -moz-column-count: 2 !important;
         column-count: 2 !important;
  }
}
@media only screen and (max-width: 642px) {
  .gridIsotope {
    display: flex !important;
    flex-direction: column !important;
    align-items: center;
  }
  .grid-item {
    left: unset !important;
  }
}
