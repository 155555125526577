body.dimmed.dimmable {
  overflow: auto !important;
}
div#question-landing {
  position: fixed;
  border-radius: 0.6em;
  pointer-events: auto;
  top: auto;
  bottom: 1%;
  right: 1%;
  left: 1%;
  width: auto;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  padding-bottom: 0;
}
div#question-landing > div.content {
  border-radius: 25px;
}
div#question-landing > div.content > h2 {
  font-size: 1.4em;
}
div#question-landing > div.content > p {
  font-size: 0.9em;
}
div#question-landing > div.actions {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  border-radius: 0 0 0.6em 0.6em;
}
body div.dimmer.visible.active:has(#question-landing) {
  background: none;
  pointer-events: none;
}
.brandedDiv {
  display: flex;
  flex-direction: column;
}
.ui.color.button {
  background-color: #32418E;
  box-shadow: 0px 0em 0px 0px #32418E inset;
  width: 100%;
  font-size: 0.9em;
  padding: 1.2em 0;
  border-radius: 0.5em;
  font-weight: 500;
  margin: 0.2em;
  color: #fff;
}
.ui.color.button:hover {
  background-color: #253068;
}
button.ui.color.inverted.button {
  background-color: transparent;
  color: #32418E;
  box-shadow: 0px 0em 0px 2px #32418E inset !important;
  margin-left: unset;
}
button.ui.color.inverted.button:hover {
  background-color: #253068;
  color: #fff;
}
div.ui.checked.toggle.checkbox label::before {
  background-color: #32418E !important;
  -moz-columns: #32418E;
       columns: #32418E;
}
div#question-landing.settingsModal {
  position: sticky;
  width: 100%;
}
div#question-landing.settingsModal > .content .privacyHeaderDiv {
  display: flex;
  justify-content: space-between;
}
div#question-landing.settingsModal > .content .privacyHeaderDiv > .ui.button.back {
  font-weight: 500;
  font-size: 1.5em;
  padding: 0 0.6em;
  border-radius: 0.5em;
}
div#question-landing.settingsModal > .actions {
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 0.5em !important;
}
div#question-landing.settingsModal > .actions > .ui.color.button,
div#question-landing.settingsModal > .actions > .brandedDiv .ui.color.button {
  width: 100%;
  padding: 1em 1em;
  margin: 0.2em 0.2em;
}
div#question-landing.settingsModal > .actions > .brandedDiv {
  width: 100%;
  flex-direction: column;
}
#question-landing > div.content > div.privacyHeaderDiv {
  margin-bottom: 1.5em;
}
.ui.toggle.checkbox input:focus:checked ~ .box:before,
.ui.toggle.checkbox input:focus:checked ~ label:before {
  background-color: #32418E !important;
}
@media only screen and (min-width: 600px) {
  .brandedDiv {
    flex-direction: row;
  }
}
@media only screen and (min-width: 900px) {
  div #question-landing {
    bottom: 1%;
    right: 1%;
    left: unset;
    max-width: 30em;
  }
  div#question-landing.settingsModal {
    max-height: 38em;
    max-width: 43em;
  }
  div#question-landing.settingsModal > .actions {
    flex-direction: row;
  }
  div#question-landing.settingsModal > .actions > .brandedDiv {
    flex-direction: row;
  }
  div#question-landing.settingsModal > .actions > .ui.color.button,
  div#question-landing.settingsModal > .actions > .brandedDiv .ui.color.button {
    width: -moz-fit-content;
    width: fit-content;
  }
}
